<template>
  <div class="position-relative section"
    :class="{ 'deactivated bg-custom-secondary': !deactivate }"
    @mouseenter="deactivate = !true"
    @mouseleave="deactivate = !false"
  >
  <div class="wrapper position-relative h-100">
    <b-row no-gutters class="row d-flex align-items-center position-relative p-0 m-0">
      <img
        src="../assets/Images/subscription_child_banner.svg"
        class="w-100 h-auto"
        alt="banner"
      />
      <span
        class="subscription_number position-absolute d-block"
        >
        <span class="text-white mr-n2">Payment</span>
        <span class="font-weight-bold">
          {{ type }}
        </span>
        </span
      >
    </b-row>
    <div no-gutters class="row content mt-n4 position-relative w-100 p-0 m-0">
        <div no-gutters class="row subscription_type pb-2 px-2 mx-auto my-2" align-v="center">
          <div class="col text-center d-flex align-items-center justify-content-around p-0 m-0" align-h="stretch">
            <img
              v-if="type === '01'"
              src="../assets/Images/all_pass_1.svg"
              width="auto"
              height="100px"
              alt="subscription_typ"
            />
            <img
              v-if="type === '02'"
              width="auto"
              height="100px"
              src="../assets/Images/all_pass_plus_vip.svg"
              alt="subscription_typ"
            />
          <!-- </b-col> -->
          <!-- <b-col style="height: 100px;" class="font-weight-bold"> -->
            <p v-if="type === '01'" class="text-left font-weight-bold">
              8주동안 <span class="position-relative highlighted">모든 수업</span>을 <br><span class="position-relative highlighted_1">마음껏 수강</span>할 수 있습니다.
            </p>
            <p v-if="type === '02'" class="text-left font-weight-bold">
              8주동안 <span class="position-relative highlighted">모든 수업</span>을 <br><span class="position-relative highlighted_1">마음껏 수강</span>할 수 있습니다.
            </p>
          </div>
          <div class="col-12 text-right p-0 m-0">
            <span class="info color-pink d-block" v-if="type === '02'"> * 지정한 수업의 VIP가 되고, 모든 수업에 VIP로 참여할 수 있는 혜택이 주어집니다. </span>
          </div>
        </div>
        <div
          no-gutters
          class=" row sub-part p-0 m-0"
          align-h="start"
          style="background: #fffdd9"
          v-if="type === '01'"
        >
          <div
            v-for="(option, index) in classTypes"
            :key="index+option.name"
            class=" my-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            <label class="w-75 m-auto d-block position-relative">
              <div
                :class="['w-100 px-2 py-2 custom-check-box d-flex selected justifi-content-start align-items-center']"
              >
                <img
                  src="../assets/Images/checked.svg"
                  alt="checked"
                />
                <span class="option pl-2 font-weight-bold text-white">{{ option.name}}</span>
                <span :class="['position-absolute option-number font-weight-bold', {'black': selected.includes(option), 'gray': !(selected.includes(option))}]">{{ index + 1 }}</span>
              </div>
            </label>
          </div>
          <div class="col text-center mr-5 p-0">
            <span class="info color-pink" v-if="type === '01'">* ALL PASS를 구매하시면 모든 수업을 자유롭게 수강하실 수 있습니다.</span>
          </div>
        </div>
        <div class="w-80 m-auto sub-part" v-if="type==='02'">
          <div class="col-12 d-flex justify-content-between m-0 p-0">
            <div>
              <img
                src="../assets/Images/vip_inactivated.svg"
                width="40px"
                height="40px"
                alt="vip_not_selected"
              />
            </div>
          </div>
          <div class="col selcect_class_trigger py-3 position-relative m-0">
            <label class="position-absolute vip-btn">
              <div
                class="w-100 px-2 py-1 custom-check-box bg-pink d-flex justifi-content-start align-items-center"
                :class="{ 'disabled_box' : (type ==='01' && selected.length !=2) }"
              >
                <img
                  src="../assets/Images/checked.svg"
                  alt="checked"
                />
                <span class="option text-white font-weight-bold" 
                  >VIP 수강생 되기</span
                >
              </div>
            </label>
            <button
              @click="openSelectClass"
              class="select_class m-auto btn d-flex justify-content-around p-0 align-items-center"
            >
              <img
                src="../assets/Images/calendar-silhouette.svg"
                width="20px"
                height="20px"
                alt="calendar-silhouette"
              />
              <div v-if="confirmed_class">
                <span class="d-block">{{ confirmed_class.teacher_name }}</span>
                <span class="d-block">{{ confirmed_class.timing }}</span>
              </div>
              <div>
                <span v-if="!(confirmed_class.teacher_name)" style="font-size:12px;">VIP로 참여할 수업을 선택하세요.</span>
              </div>
              <b-icon class="arrow-down" icon="triangle-fill" scale="0.7" rotate="180"></b-icon>
            </button>
          </div>
        </div>
    </div>
  </div>
  <b-row align-v="center" :class="['m-0 pt-1 position-relative bg-black payment-button-section w-100 mt-auto', {'bg-custom-secondary': !deactivate}]">
          <button
            :disabled="(selectVIP&&!selected_class.teacher_name)"
            :class="['payment-button btn font-weight-bold custom-button m-auto d-flex justify-content-around align-items-center', {'bg-white text-dark': !deactivate, 'bg-custom-secondary text-white': deactivate}]"
            @click="registerClass"
          >
            <span class="d-block">결제하기</span>
            <span class="d-block">￦ {{ totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
            <b-icon icon="arrow-right"></b-icon>
          </button>
        </b-row>
  <b-modal
  ref="select-class"
  centered
  scrollable
  content-class="p-0"
  footer-class="bg-select-class p-0"
  body-class="bg-select-class p-0" header-class="bg-select-class"
  >
  <template #modal-title>
            <div class="d-flex justify-content-between mb-1">
              <img
                src="../assets/Images/vip_inactivated.svg"
                width="30px"
                height="30px"
                alt="vip_not_selected"
              />
              <span class="title-text ml-3"><b>VIP</b> 수강생으로 참여하고 싶은 클래스를 선택해 주세요.</span>
            </div>
  </template>
    <select-class @selected_class="selectedClass" :classes="classesFilter"></select-class>
    <template #modal-footer>
      <div class="row py-3 justify-content-center confirm-button position-sticky m-0">
          <button @click="save" class="btn w-50" style="background: #FF2A58;border-radius: 8px;border: none">선택완료</button>
        </div>
    </template>
  </b-modal>
  </div>
</template>

<script>
import SelectClass from "./SelectClass";
import { mapGetters, mapMutations } from "vuex";
import * as _ from "lodash";
export default {
  name: "modal2",
  props: ["subscription_type", "subscription_grade", "subscription_price"],
  components: {
    "select-class": SelectClass,
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      allCoupons: "allCoupons",
      tokens: "tokens",
      // classTypes: "classTypes",
      classes: "classes",
    }),
    type() {
      if (this.subscription_type === "all_pass" && this.subscription_grade === "NORMAL") {
        return "01";
      }
      if (this.subscription_type === "all_pass" && this.subscription_grade === "VIP") {
        return "02";
      }
    },
    availableCooupons() {
      return this.allCoupons.length;
    },
    vip_price() {
      return 30000
    },
    totalPrice() {
      if(this.selectVIP && this.selectedCoupon) {
        return this.subscription_price + this.vip_price - this.selectedCoupon.discount;
      } else if(this.selectVIP) {
        return this.subscription_price + this.vip_price;
      } else if(this.selectedCoupon) {
        return this.subscription_price - this.selectedCoupon.discount
      } else {
        return this.subscription_price;
      }
    },
    classesFilter() {
      const selectedClassTypeIds = this.selected.map((item) => item._id);
      if (this.classes && this.classes.length > 0) {
        const result = this.classes.map((classItem) => {
          if (this.subscription_type === 'all_pass' || selectedClassTypeIds.includes(classItem.type._id) ||selectedClassTypeIds.includes(classItem.type)) {
            return {
              id: classItem._id,
              teacher_name:  _.get(classItem, 'teacher.name'),
              timing: classItem.classTime,
              ...classItem
            };
          }
        });
        return result.filter((item) => item !== undefined);
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      deactivate: true,
      selected: [],
      selected_class: { teacher_name: "", timing:""},
      confirmed_class: { teacher_name: "", timing:""},
      selectClass: false,
      selectVIP: this.subscription_grade === 'VIP' ? true: false,
      error: false,
      isSafari: false,
      selectedCoupon: null,
      classTypes: [
        {
          name: "일상회화 수업"
        },
        {
          name: "초급회화 수업"
        },
        {
          name: "중고급 수업"
        },
        {
          name: "비즈니스 수업"
        }
      ]
    };
  },
  watch: {
    selected_class(v) {
      console.log(v);
    }
  },
  methods: {
    selectedClass(e) {
      this.selected_class = e;
    },
    save() {
      this.confirmed_class = this.selected_class;
      this.$refs['select-class'].hide();
      console.log("class selected")
    },
    openSelectClass() {
      this.$store.dispatch("getClasses");
      this.$refs['select-class'].show();
      // this.selectClass = true;
    },
    toggleSelect(e, i) {
      if (this.type === "01") {
        if (e && this.selected.length < 2) {
          this.selected.push(i);
          
        } else {
          
          this.selected = this.selected.filter((o) => o !== i);
          if (this.selected.length !==2) {
            this.selected_class.teacher_name = "";
            this.selected_class.timing = "";
            this.selectVIP = false;
            this.selectClass = false;
          }
          else if( this.selected.length === 2 && true ) {
          this.error= true;
          setTimeout(() => {
            this.error = false;
          }, 2000);
      }
        }
      }
    },
    async registerClass() {
      let registeringDetails = {
        selectedClassType: this.selected,
        selectedClass: this.selected_class,
        selectVIP: this.selectVIP,
        subscriptionType: this.subscription_type,
        price: this.selectVIP ? (this.subscription_price + this.vip_price) : this.subscription_price,
        coupon: this.selectedCoupon
      };
      if(this.tokens && this.tokens?.accessToken) {
        await this.$store.dispatch("registerClass", registeringDetails);
      } else {
        this.$store.commit('setPath', '/subscription');
        this.$router.push("/auth/login");
      }
    }
  },
  mounted() {
    if(window.safari) {
      this.isSafari = true;
    } else {
      this.isSafari = false;
    }
    console.log(this.isSafari);
  },
};
</script>

<style scoped>
.section {
  border-radius: 8px;
  padding: 3px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.wrapper {
  border-radius: 7px;
  overflow: hidden;
  background-color: #ffffff;
}

.highlighted, .highlighted_1 {
  font-weight: 700;
}

.highlighted::before {
  position: absolute;
  content: '';
  background-color: #FFE63A;
  width: 100%;
  opacity: 0.5;
  height: 8px;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.highlighted_1::before {
  position: absolute;
  content: '';
  background-color: #FF2A58;
  width: 100%;
  opacity: 0.5;
  height: 8px;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.title-text {
  font-size: 14px !important;
}

.payment-button-section {
  /* bottom: 0; */
  top: -80px;
  height: 80px;
  left: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.content {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 20;
  margin-right: 1.5px;
  background-color: #ffffff;
}

.disabled_box {
  background-color :lightgray !important;
}

.subscription_number {
  right: 50px;
  transform: scale(1.5);
}

.subscription_number span:nth-child(2) {
  font-size: 50px;
}

.subscription_number span:nth-child(1) {
  font-size: 10px;
}
.subscription_type {
  border-bottom: 2px solid #ffe63a;
}

.applied-text{
  font-size:12px;
  margin-bottom: 28px;
}

.custom-check-box {
  border-radius: 25px;
}
.unselected {
  background: #d8d8d8;
}

.selected {
  background: #ffe63a;
}

@media screen and (min-width:601px) {
  .payment-button {
    width: 50%!important;
  }
}
@media screen and (max-width:600px) {
  .payment-button {
    width: 80%!important;
  }
}

.info {
  font-size: 10px;
}

.color-pink {
  color: #ff2a58;
}
.bg-pink {
  background: #ff2a58;
}
.custom-button {
  border-radius: 8px;
  border: none;
}
.bg-black {
  background: #000000;
}
.selcect_class_trigger {
  border-radius: 8px 30px 8px 8px;
  height: 90px;
  border: 1px solid rgba(186, 186, 186, 0.8);
}
.vip-btn {
  top: -25%;
  left: 10%;
}
.select_class {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 60px;
  border: 1px solid rgba(186, 186, 186, 0.8);
  background: none;
  outline: none;
  color: black;
  min-height: 60px;
}

.arrow-down {
  color: #535353;
}
.option-number {
    padding-left: 0.8em;
    top: -1em;
    right: 2em;
    transform: scale(3);
}

.option {
  font-size: 14px;
}


.cursor_pointer {
  cursor: pointer;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  .select_class {
        width: 100%;
    }
 }

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) { 
    .select_class {
        width: 100%;
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) { 
    .select_class {
        width: 100%;
    }
 }

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 
    .select_class {
        width: 75%;
    }
 }

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) { 
    .select_class {
        max-width: 1199px;
    }
 }
</style>