<template>
  <b-container
    fluid
    class="d-flex p-0 m-0 justify-content-center align-items-center select-classes w-100"
  >
    <b-row class="w-100 m-auto list-container position-relative h-100">
       <b-col>
    <!--    <b-row class="position-sticky list">
          <b-col cols="12" class="d-flex justify-content-between mb-1">
            <div>
              <img
                src="../assets/Images/vip_inactivated.svg"
                width="30px"
                height="30px"
                alt="vip_not_selected"
              />
            </div>
            <div>
              <span><b>VIP</b> 수강생으로 참여하고 싶은 클래스를 선택해 주세요.</span>
            </div>
            <div>
              <b-icon icon="x" scale="1.2" class="cursor_pointer" @click="$emit('close')"></b-icon>
            </div>
          </b-col>
          <b-row class="h-line m-auto"></b-row>
        </b-row> -->
        <b-row no-gutters class="mt-3">
            <b-col class="w-100">
                <b-row class="class w-100 mx-1 my-2" v-for="(classs, index) in modified_classes" :key="index" align-v="center" :class="classs.isClassFull == true ? 'class-sold-out w-100 mx-1 my-2' : 'class w-100 mx-1 my-2'">
                    <b-col sm="3" lg="auto" xl="auto" md="3" class="text-center">
                        <img :src="require(`../assets/Images/${classs.img}.png`)" class="teacher_pic" width="50px" height="60px" alt="teacher_image">
                    </b-col>
                    <b-col lg="7" xl="7" sm="6" md="6">
                        <b-row no-gutters class="mt-lg-2">
                            <b-badge pill :style="`background-color: ${classTypeColor(classs)}`">{{ classs.type &&  classs.type.name ? classs.type.name : '' }}</b-badge>
                            <b-badge pill variant="primary" v-for="(scheduleItem, j) in classs.schedule" :key="j+200">{{ scheduleItem ? scheduleItem : ""}}</b-badge>
                        </b-row>
                        <b-row no-gutters class="w-100 my-sm-2 my-xs-2">
                            <b-col cols="auto">
                                <span class="d-block teacher h5 p-0"> {{ classs.teacher_name }} </span>
                            </b-col>
                            <b-col class="pl-2">
                                <img src="../assets/Images/clock.svg" alt="clock">
                                <span class="time pl-2"> {{ classs.timing }} </span>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="auto" class="text-center ml-auto mr-1" v-if="classs.isClassFull != true">
                        <img src="../assets/Images/vip_inactivated.svg" class="mr-3" width="30px" height="30px" alt="vip_not_selected"/>
                        <label class="cursor_pointer" v-if="classs.isClassFull != true">
                            <b-form-radio class="d-none" name="class" v-model="selected" :value="classs.id" ></b-form-radio>
                            <img src="../assets/Images/unchecked.svg" style="border: 1px solid lightgray; border-radius: 25px;" v-if="!(selected === classs.id)" width="24px" height="24px" alt="unchecked"/>
                            <img src="../assets/Images/checked.svg" style="border: 1px solid pink; border-radius: 25px;" v-if="selected === classs.id" width="24px" height="24px" alt="checked"/>
                        </label>
                    </b-col>
                    <b-col cols="auto" class="text-center mr-1" v-if="classs.isClassFull == true">
                        <img src="../assets/Images/sold_out.png" class="" width="64px" height="50px" alt="sold_out"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!-- <b-row align-h="center" class="py-3 confirm-button position-sticky">
          <b-button @click="save" class="w-50" style="background: #FF2A58;border-radius: 8px;border: none">선택완료</b-button>
        </b-row> -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as _ from "lodash";
export default {
  name: "SelectClass",
  props: ["classes"],
  computed: {
    modified_classes() {
      const result = this.classes.map((c) => {
        return { ...c, img: c.teacher_name.toLowerCase() };
      });
      return _.sortBy(result, 'teacher_name')
    },
  },
  data() {
      return {
          selected: null
      }
  },
  watch: {
    selected(v) {
      this.$emit('selected_class', this.classes.find(cl => cl.id === v));
    }
  },
  methods: {
    // save() {
    //   this.$emit('selected_class', this.classes.find(cl => cl.id === this.selected));
    //   this.$emit('close');
    // },
    classTypeColor(classObj) {
      const key = classObj?.type?.code;
      switch (key) {
        case "DAILY_CONVERSATION":
          return "purple";
          break;
        case "ELEMENTARY_CONVERSATION":
          return "orange";
          break;
        case "INTERMEDIATE":
          return "cyan";
          break;
        case "BUSINESS":
          return "green";
          break;
        default:
          return "orange";
          break;
      }
    },
  },
  mounted() {
    console.log(this.classes)
  }
};
</script>

<style scoped>

.list {
  top: 0;
  z-index: 2000;
  /* background: #fff4cd; */
}

.teacher_pic {
  border-radius: 5px;
}
.confirm-button {
  bottom: 0;
  background: #fff4cd;
  z-index: 2000;
}
.select-classes {
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200; */
  /* height: 90%; */
  overflow: hidden;
}
.list-container {
  /* background: #fff4cd; */
  /* border: 0.5px solid #ffd602; */
  box-sizing: border-box;
  /* border-radius: 10px; */
  /* overflow: auto; */
}
.list-container::-webkit-scrollbar {
  width: 0.5em;
}
 
.list-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 25px;
}
.h-line {
width: 98%;
height: 3px;
background: #FFD241;
border-radius: 10px;
}
.time {
    font-size: 10px;
}
.class-sold-out {
  border-radius: 5px;
  background: darkgrey !important;
}
.class {
    border-radius: 5px;
    background: white;
}
.cursor_pointer {
  cursor: pointer;
}
</style>